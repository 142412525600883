import { default as _91_46_46_46slug_930NssoJ9SMzMeta } from "/vercel/path0/apps/storefront/src/pages/[...slug].vue?macro=true";
import { default as bedanktDqk5g5k5NCMeta } from "/vercel/path0/apps/storefront/src/pages/account/bedankt.vue?macro=true";
import { default as bestellingenA1KFeOlTtCMeta } from "/vercel/path0/apps/storefront/src/pages/account/bestellingen.vue?macro=true";
import { default as indexkG61FKwgdqMeta } from "/vercel/path0/apps/storefront/src/pages/account/index.vue?macro=true";
import { default as inloggencrIGAY2syqMeta } from "/vercel/path0/apps/storefront/src/pages/account/inloggen.vue?macro=true";
import { default as registrerentIXJgPo8zuMeta } from "/vercel/path0/apps/storefront/src/pages/account/registreren.vue?macro=true";
import { default as wachtwoord_45resettenNevIIn8oxVMeta } from "/vercel/path0/apps/storefront/src/pages/account/wachtwoord-resetten.vue?macro=true";
import { default as wachtwoord_45vergetenlZXRjBSeDiMeta } from "/vercel/path0/apps/storefront/src/pages/account/wachtwoord-vergeten.vue?macro=true";
import { default as _91_46_46_46all_93bNEpDY25SMMeta } from "/vercel/path0/apps/storefront/src/pages/assortiment/[...all].vue?macro=true";
import { default as _91orderId_935bzTCCX8G5Meta } from "/vercel/path0/apps/storefront/src/pages/bestellen/bedankt/[orderId].vue?macro=true";
import { default as gegevensHB4s54fmUmMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/gegevens.vue?macro=true";
import { default as inloggenP5TwsiFx9AMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/inloggen.vue?macro=true";
import { default as leveringVO30bFD7JAMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/levering.vue?macro=true";
import { default as levertijdjeEvXvwmQ7Meta } from "/vercel/path0/apps/storefront/src/pages/bestellen/levertijd.vue?macro=true";
import { default as overzicht4LflZjASNoMeta } from "/vercel/path0/apps/storefront/src/pages/bestellen/overzicht.vue?macro=true";
import { default as contactC9DNgJkeBiMeta } from "/vercel/path0/apps/storefront/src/pages/contact.vue?macro=true";
import { default as december_45bezorgen_452024sbRGHyTK2EMeta } from "/vercel/path0/apps/storefront/src/pages/december-bezorgen-2024.vue?macro=true";
import { default as _91id_930eMhefCJcUMeta } from "/vercel/path0/apps/storefront/src/pages/detail/[id].vue?macro=true";
import { default as _91slug_93BVARLhus2AMeta } from "/vercel/path0/apps/storefront/src/pages/product/[slug].vue?macro=true";
import { default as _91name_93ecl0QmqgBKMeta } from "/vercel/path0/apps/storefront/src/pages/winkels/[name].vue?macro=true";
import { default as _91query_93GM7PX4bg6TMeta } from "/vercel/path0/apps/storefront/src/pages/zoeken/[query].vue?macro=true";
import { default as component_45stubg44a87LCzTMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubg44a87LCzT } from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/apps/storefront/src/pages/[...slug].vue")
  },
  {
    name: "account-bedankt",
    path: "/account/bedankt",
    meta: bedanktDqk5g5k5NCMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/bedankt.vue")
  },
  {
    name: "account-bestellingen",
    path: "/account/bestellingen",
    meta: bestellingenA1KFeOlTtCMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/bestellingen.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexkG61FKwgdqMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/index.vue")
  },
  {
    name: "account-inloggen",
    path: "/account/inloggen",
    meta: inloggencrIGAY2syqMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/inloggen.vue")
  },
  {
    name: "account-registreren",
    path: "/account/registreren",
    meta: registrerentIXJgPo8zuMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/registreren.vue")
  },
  {
    name: "account-wachtwoord-resetten",
    path: "/account/wachtwoord-resetten",
    meta: wachtwoord_45resettenNevIIn8oxVMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/wachtwoord-resetten.vue")
  },
  {
    name: "account-wachtwoord-vergeten",
    path: "/account/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenlZXRjBSeDiMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/account/wachtwoord-vergeten.vue")
  },
  {
    name: "assortiment-all",
    path: "/assortiment/:all(.*)*",
    component: () => import("/vercel/path0/apps/storefront/src/pages/assortiment/[...all].vue")
  },
  {
    name: "bestellen-bedankt-orderId",
    path: "/bestellen/bedankt/:orderId()",
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/bedankt/[orderId].vue")
  },
  {
    name: "bestellen-gegevens",
    path: "/bestellen/gegevens",
    meta: gegevensHB4s54fmUmMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/gegevens.vue")
  },
  {
    name: "bestellen-inloggen",
    path: "/bestellen/inloggen",
    meta: inloggenP5TwsiFx9AMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/inloggen.vue")
  },
  {
    name: "bestellen-levering",
    path: "/bestellen/levering",
    meta: leveringVO30bFD7JAMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/levering.vue")
  },
  {
    name: "bestellen-levertijd",
    path: "/bestellen/levertijd",
    meta: levertijdjeEvXvwmQ7Meta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/levertijd.vue")
  },
  {
    name: "bestellen-overzicht",
    path: "/bestellen/overzicht",
    meta: overzicht4LflZjASNoMeta || {},
    component: () => import("/vercel/path0/apps/storefront/src/pages/bestellen/overzicht.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/vercel/path0/apps/storefront/src/pages/contact.vue")
  },
  {
    name: "december-bezorgen-2024",
    path: "/december-bezorgen-2024",
    component: () => import("/vercel/path0/apps/storefront/src/pages/december-bezorgen-2024.vue")
  },
  {
    name: "detail-id",
    path: "/detail/:id()",
    component: () => import("/vercel/path0/apps/storefront/src/pages/detail/[id].vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    component: () => import("/vercel/path0/apps/storefront/src/pages/product/[slug].vue")
  },
  {
    name: "winkels-name",
    path: "/winkels/:name()",
    component: () => import("/vercel/path0/apps/storefront/src/pages/winkels/[name].vue")
  },
  {
    name: "zoeken-query",
    path: "/zoeken/:query()",
    component: () => import("/vercel/path0/apps/storefront/src/pages/zoeken/[query].vue")
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/home",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/aa-fles",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/aardappelpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Aardappelpartjes-300-gram-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/alkmaar",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/almere",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/alphenaandenrijn",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/amsterdam-osdorp",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/anti-kluifpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/anti-kluifpan-deluxe",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/anti-kluifpan-deluxe-xxl",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/anti-kluif-partyschotel",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/assen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/ballenpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bami-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bami-vega-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/barendrecht",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bereiding-overige-producten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bergenopzoom",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/berkelenrodenrijs",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bier-kippie",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bijgerechten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/bijproducten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Borg-Kippiepan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/borg-kippiepan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/borg-kippiepan-naar-15-euro",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/borgpannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/breda",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/broodje-kip-burger",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/broodje-kip-frikandel",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/broodje-kip-kroket",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/broodjes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/cdn-cgi/l/email-protection",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/chinese-bami-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/chinese-bami-vega-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/chinese-nasi-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/chocomel-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/coca-cola-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/coca-cola-flesje",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/coca-cola-zero-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-bami-en-kip-in-ketjapsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-bami-en-kip-in-pangangsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-chinese-bami-en-kip-in-ketjapsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-chinese-bami-kip-in-pangangsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-mihoen-en-kip-in-ketjapsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-mihoen-en-kip-in-pangangsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-nasi-en-kip-in-ketjapsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/combimaaltijdpan-nasi-en-kip-in-pangangsaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/delft",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/denbosch",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/denhaag-leyweg",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/denhaag-savorninlohmanplein",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/detail/3e114fa71d404f1aaae65a73994002da",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/detail/e56bbf9155b14cd08033088a62f648e4",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/doenerpakket-knoflooksaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/doenerpakket-piripirisaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/doetinchem",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/donerpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/dordrecht-centrum",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/dordrecht-sterrenburg",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/drankjes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/dronten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/ede",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/eindhoven",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/emmen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/enschede",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/fanta-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/fanta-flesje",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/feestpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/fernandes-cherry-bouquet-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/fernandes-green-punch-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/fristi-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/gehaktbal-half-om-half",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/goes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/gorinchem",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/gouda",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/grillproducten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/grillworst-kaas-330-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/grillworst-naturel-330-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/haarlem",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hapjes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hapjespannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/heerhugowaard",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hellevoetsluis",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hilversum",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hoofddorp",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hoofdkantoor",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hoofdkantoor-van-kippie-gaat-verhuizen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/hoogezand",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/houten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/huizen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/inloggen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kids-knak-pan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip-balletjes-10-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Kip-Doner",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip-in-cashewsaus-250-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip-in-ketjapsaus-250-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip-in-pangangsaus-250-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kip-in-peperroomsaus-250-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippeling-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippelingpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippenpoot-naturel",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippenpoot-pittig",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippenpoot-zoet",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippenpoten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippiebox-kipballetjes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippiebox-kippeling",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-gaat-thuisbezorgen-met-drones",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippiepannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Kippiepannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-pops-10-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-popspan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-tester-1",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-tester-2",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-tester-3",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-tester-4",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kippie-tester-5",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kipsate-ketjap-5-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kipsate-knoflook-5-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kipsate-pikant-5-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kipsate-pinda-5-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kluifjes-naturel-500-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kluifjes-pittig-500-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kluifjes-zoet-500-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kluifpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/knoflooksaus-150ml",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/komkommersalade-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/kroepoek",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/leiden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/leiderdorp",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/leidschendam",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/leusden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/lipton-ice-tea-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/lipton-ice-tea-green-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/loempia-kip-3-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/losse-snacks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijden-kip",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijden-vega",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-bami-en-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-bami-en-nasi",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-bami-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-chinese-nasi",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-nasi",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-nasi-en-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpan-nasi-en-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maaltijdpannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maarssen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maassluis",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/maastricht",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/middelburg",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Mihoen-300-gram-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Mihoen-300-gram-vega-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/mini-kip-sate-10-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/Mini-Sate-Ballenpan-XL",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/mini-satepan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/naaldwijk",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/nasi-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/nasi-vega-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/nijmegen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/onze-winkels",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/oosterhout",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/overige-bijgerechten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/overige-producten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/papendrecht",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/party-producten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/partyschotel-magnetron",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/partyschotel-oven",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/peperroomsaus-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pindasaus-150ml",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/piripirisaus-150ml",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolet-braadworst",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolet-gehaktbal",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolet-kip",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolet-kipbal",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolet-kippeling",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pistolets",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/pitabroodjes-5-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/plus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/purmerend",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rauwkost-salade",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/red-bull-blik",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/registreren",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/ridderkerk",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rijswijk",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/roosendaal",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rotirol",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rotterdam-hoogvliet",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rotterdam-ijsselmonde",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/rotterdam-ommoord",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/sajoerboontjes-300-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/satepan-ketjap",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/satepan-pindasaus",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/satepan-piripiri",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/sauzen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/schiedam",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/snackbroodjes",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/snackpan",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/snackpan-xl",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/snackpan-xxl",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/snacks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spa-blauw-fles",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribpan-pittig",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribpan-zoet",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribs",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribs-pittig-500-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribs-pittig-500-gram-gesneden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribs-zoet-500-gram",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spareribs-zoet-500-gram-gesneden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/spijkenisse",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/stokbroden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/stokbrood-kip",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/tilburg-wagnerplein",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/tilburg-westermarkt",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/tortilla-rol-kip-2-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/uden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/utrecht-overvecht",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/varkensvlees",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/veenendaal",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-bami-en-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-bami-en-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-bami-en-nasi",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-met-4-soorten-maaltijden",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-mihoen-en-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-nasi",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-nasi-en-chinese-bami",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vega-maaltijdpan-nasi-en-mihoen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vegapannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/verlanglijstje",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vlaardingen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vleespannen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/vleesschotels",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/wachtwoord-resetten",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/waddinxveen",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/witte-puntjes-12-stuks",
    component: component_45stubg44a87LCzT
  },
  {
    name: component_45stubg44a87LCzTMeta?.name,
    path: "/zwijndrecht",
    component: component_45stubg44a87LCzT
  }
]