<script setup lang="ts">
import { useNotivue, type NotivueItem } from "notivue";

const { isStreamPaused } = useNotivue();

defineProps<{
	item: NotivueItem;
}>();
</script>

<template>
	<div class="absolute left-0 right-0 bottom-0 h-1.5 bg-neutral-100">
		<div
			class="notify-progress absolute bottom-0 left-0 w-full h-1.5 bg-yellow"
			:style="{
				animationPlayState: isStreamPaused ? 'paused' : 'running',
				'--duration': `${item.duration}ms`
			}"
		/>
	</div>
</template>

<style scoped>
@media (prefers-reduced-motion: no-preference) {
	.notify-progress {
		animation: Progress_KF var(--duration) linear forwards;
		transform-origin: left;
	}

	@keyframes Progress_KF {
		0% {
			transform: scaleX(1);
		}
		100% {
			transform: scaleX(0);
		}
	}
}

@media (prefers-reduced-motion: reduce) {
	.notify-progress {
		display: none;
	}
}
</style>
