<script setup lang="ts">
import type { NotivueItem } from "notivue";
import type { AddToCartItem } from "./NotifyAddToCart.vue";
import type { NotifyItem } from "./Notify.vue";

const castToAddToCartItem = (item: NotivueItem) => item as AddToCartItem;
const castToNotifyItem = (item: NotivueItem) => item as NotifyItem;
</script>
<template>
	<Notivue v-slot="item">
		<NotivueSwipe :item="item">
			<!-- Different layouts of notify -->
			<NotifyAddToCart v-if="item.props.isAddToCart" :item="castToAddToCartItem(item)" />
			<Notify v-else :item="castToNotifyItem(item)" />
		</NotivueSwipe>
	</Notivue>
</template>
