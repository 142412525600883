<script setup lang="ts">
import type { NotivueItem } from "notivue";
import type { Icon } from "@kippie/ui";
import {} from "notivue";

declare type ItemProp = Record<string, any>;

// Extend NotivueItem with additional props (any object is added as props in NotivueItem)
// And continue the extension with the default props from Notify
export type NotifyItem<T extends ItemProp = ItemProp> = NotivueItem<
	{
		imageUrl?: string;
		profileUrl?: string;
		icon?: Icon;
		iconColor?: "white" | "green" | "red" | "yellow" | "brown" | "gray" | "black" | "amber-500";
	} & T
>;

const props = defineProps<{ item: NotifyItem }>();
const {
	title,
	message,
	ariaRole,
	ariaLive,
	type,
	props: { imageUrl, onClick },
	clear
} = props.item;

let {
	props: { iconColor, icon }
} = props.item;

switch (type) {
	case "success":
		icon ??= "check";
		iconColor ??= "black";
		break;
	case "info":
		icon ??= "info";
		iconColor ??= "black";
		break;
	case "warning":
		icon ??= "info";
		iconColor ??= "amber-500";
		break;
	case "error":
		icon ??= "info";
		iconColor ??= "red";
		break;
}
</script>
<template>
	<div
		class="flex justify-between w-[400px] max-w-full rounded-md overflow-hidden bg-white text-black-light shadow-toggle select-none"
		:class="{
			'cursor-pointer': !!onClick
		}"
		@click="
			() => {
				if (onClick) onClick();
				clear();
			}
		"
	>
		<slot name="image">
			<div v-if="imageUrl" class="bg-yellow p-2 flex items-center text-yellow">
				<img :src="imageUrl" class="w-10" />
			</div>
		</slot>
		<div class="relative flex flex-1 pb-1">
			<slot name="icon">
				<div v-if="icon && !imageUrl" class="flex items-start m-3 mr-0">
					<SvgIcon :name="icon" :class="`text-${iconColor || 'white'}`" />
				</div>
			</slot>

			<slot name="default">
				<div
					v-if="title || message"
					class="m-3 flex-1"
					:class="{
						'ml-6': !icon && !imageUrl
					}"
				>
					<h3 v-if="title">{{ title }}</h3>
					<p v-if="message" :role="ariaRole" :aria-live="ariaLive" aria-atomic="true">
						{{ message }}
					</p>
				</div>
			</slot>

			<slot name="progress">
				<NotifyProgress :item="item" />
			</slot>

			<slot name="close">
				<button class="p-3 self-start" @click.stop="clear">
					<SvgIcon name="close" />
				</button>
			</slot>
		</div>
	</div>
</template>
