<script setup lang="ts">
import type { NotifyItem } from "./Notify.vue";

export type AddToCartItem = NotifyItem<{
	isAddToCart: boolean;
	product: {
		label: string;
		thumbUrl: string;
	};
}>;

const { open } = useShoppingCartView();

const props = defineProps<{ item: AddToCartItem }>();

const notifyItem = {
	...props.item,
	message: `${props.item.props.product.label} is toegevoegd aan je winkelmandje`,
	props: {
		...props.item.props,
		imageUrl: props.item.props.product.thumbUrl,
		onClick: open
	}
};
</script>
<template>
	<Notify :item="notifyItem" />
</template>
