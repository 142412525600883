<script setup lang="ts">
import type { Schemas } from "#shopware";
import * as Mapbox from "@mapbox/search-js-core";
import type { GeocodingFeature } from "@mapbox/search-js-core";
import debounce from "lodash/debounce";
import type { Point } from "~/types/storeLocator";

const MapboxGeocode = Mapbox.GeocodingCore;

const props = defineProps<{
	onlyPickupLocations?: boolean;
}>();

const emit = defineEmits<{
	(_e: "loaded", _location: Schemas["KwbPickupLocation"][]): void;
	(_e: "search", _coords: Point): void;
}>();

const { coords, isSupported, error } = useGeolocation();

const accessToken = (useRuntimeConfig().public.mapbox as any).accessToken;

const searchQuery = ref("");
const isLoadingPickLocations = ref(false);
const showEmptyMessage = ref(false);
const geoLocation = ref<Point>();

const { state: data, isLoading: pending } = useShopwarePickupLocations(
	geoLocation as Ref<Point>,
	props.onlyPickupLocations
);
const searchResults = ref<GeocodingFeature[]>([]);

const search = debounce(async (query: string, finishLoading: () => void) => {
	if (!query) {
		searchResults.value = [];
		finishLoading();
		return;
	}

	const geocode = new MapboxGeocode({
		accessToken,
		language: "nl",
		country: "nl,de,be",
		types: "place,postcode",
		limit: 5
	});

	searchResults.value = (await geocode.forward(query, { autocomplete: true })).features;

	finishLoading();
}, 500);

const onSelect = (feature: GeocodingFeature, updateQuery: (newValue: string) => void) => {
	searchQuery.value = feature.properties.name_preferred;
	geoLocation.value = {
		lat: feature.geometry.coordinates[1],
		lng: feature.geometry.coordinates[0]
	};
	searchResults.value = [];

	updateQuery(feature.properties.name_preferred);
};

const sortedLocations = computed(() =>
	(data.value as Schemas["KwbPickupLocation"][])?.slice().sort((a, b) => a.city.localeCompare(b.city))
);

const groupedLocations = computed(() => {
	return (
		(sortedLocations.value as Schemas["KwbPickupLocation"][])?.reduce(
			(acc, location) => {
				const firstLetter = location.city[0];

				if (!acc[firstLetter]) {
					acc[firstLetter] = [];
				}

				acc[firstLetter].push(location);

				return acc;
			},
			{} as Record<string, Schemas["KwbPickupLocation"][]>
		) ?? {}
	);
});

const onSearchCurrentLocation = () => {
	if (!!geoLocation.value && !searchQuery.value && !coords.value) return;

	searchQuery.value = "";
	geoLocation.value = {
		lat: coords.value.latitude,
		lng: coords.value.longitude
	};
};

watch(geoLocation, (location: Point) => {
	if (!location) return;

	emit("search", location);
});

watchOnce(coords, () => {
	onSearchCurrentLocation();
});

const hasUserLocation = computed(() => !!geoLocation.value && !searchQuery.value);
</script>

<template>
	<div class="flex flex-col gap-6">
		<InputAutocomplete
			:suggestions="searchResults as GeocodingFeature[]"
			label="Zoek op postcode of plaatsnaam"
			:success="isSupported && !error"
			@search="search"
			@select="onSelect"
		>
			<template #result="{ result }">
				{{ result.properties.name_preferred }}
			</template>
			<template #icon v-if="isSupported && !error">
				<button
					@click="onSearchCurrentLocation"
					class="flex items-center h-full"
					:class="{
						'text-blue-500': hasUserLocation
					}"
				>
					<SvgIcon name="location" />
				</button>
			</template>
		</InputAutocomplete>

		<span class="text-gray text-sm" v-if="showEmptyMessage">Geen winkels gevonden voor '{{ searchQuery }}'</span>
		<span class="text-sm text-gray" v-else-if="geoLocation && !searchQuery"> Winkels in jouw omgeving </span>
		<span class="text-sm text-gray" v-else>
			{{ searchQuery ? `Winkels in de buurt van ${searchQuery}` : `Alle winkels` }}
		</span>

		<div
			class="rounded flex flex-col items-center justify-center gap-6 bg-gray-200 pt-6 px-4 text-sm pb-0 overflow-hidden"
			v-if="pending || isLoadingPickLocations"
		>
			<span class="text-gray">Even zoeken...</span>
			<KippieLoader class="text-gray -mb-[3px]" />
		</div>

		<template v-if="!searchQuery && !geoLocation">
			<div
				class="flex flex-col gap-4"
				v-for="[letter, locations] in Object.entries(groupedLocations)"
				:key="`location-group-${letter}`"
			>
				<p class="font-bold text-xl">{{ letter }}</p>

				<LocationResult v-for="location in locations" :key="location.id" :location="location">
					<template v-for="(_, name) in $slots" #[name]="slotData">
						<slot :name="name" v-bind="slotData" />
					</template>
				</LocationResult>
			</div>
		</template>
		<template v-else>
			<LocationResult
				v-for="location in data?.sort((a, b) => (a.calculatedDistance ?? 0) - (b.calculatedDistance ?? 0))"
				:key="location.id"
				:location="location"
			>
				<template v-for="(_, name) in $slots" #[name]="slotData">
					<slot :name="name" v-bind="slotData" />
				</template>
			</LocationResult>
		</template>
	</div>
</template>
