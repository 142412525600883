import revive_payload_client_ypFRg6eV0I from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3CWhM481bg from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OszIqskGdA from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_gBACMkWDMs from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.24.4_vite@5.4.10_@types+node@20.17.6_terser@5_q4qca3qy2w7chzcrb3fpryfu4e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_vYN2STK3JD from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LiGirYPT4Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_AlBHXg2ovc from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YCvsS98fXN from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/vercel/path0/apps/storefront/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/storefront/.nuxt/components.plugin.mjs";
import prefetch_client_s5TpOOgZg9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@20.17.6_@upstash+redis@1.34.3_@vercel+kv@3.0._g7q456l6qypm2yb5nwt62vpy7a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _001_notivue_client_bE2FHKqHiu from "/vercel/path0/apps/storefront/.nuxt/001.notivue.client.mjs";
import plugin_TXXZa8g6uM from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@8.0.0_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.24.4_vue-router@4.4.5_vue@3.5._f5sxwibsvnxj3nvf63y67232by/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_z7khRKagrB from "/vercel/path0/node_modules/.pnpm/@storyblok+nuxt@6.1.1/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_dZloDonpAi from "/vercel/path0/node_modules/.pnpm/@shopware-pwa+nuxt3-module@1.0.6_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@shopware-pwa/nuxt3-module/plugin.ts";
import trengo_client_Slo7HE1gBd from "/vercel/path0/apps/storefront/src/plugins/trengo.client.ts";
export default [
  revive_payload_client_ypFRg6eV0I,
  unhead_3CWhM481bg,
  router_OszIqskGdA,
  _0_siteConfig_gBACMkWDMs,
  payload_client_vYN2STK3JD,
  navigation_repaint_client_LiGirYPT4Y,
  check_outdated_build_client_AlBHXg2ovc,
  chunk_reload_client_YCvsS98fXN,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_s5TpOOgZg9,
  _001_notivue_client_bE2FHKqHiu,
  plugin_TXXZa8g6uM,
  plugin_z7khRKagrB,
  plugin_dZloDonpAi,
  trengo_client_Slo7HE1gBd
]